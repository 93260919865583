import {
  ERROR_ALERT,
  INFO_ALERT,
  SUCCESS_ALERT,
  WARNING_ALERT,
} from "../actions/commonAction";

const commonReducer = (state, { type, payload }) => {
  switch (type) {
    case SUCCESS_ALERT:
      return {
        ...state,
        alertType: "success",
        alertMessage: payload,
      };
    case ERROR_ALERT:
      return {
        ...state,
        alertType: "error",
        alertMessage: payload,
      };
    case WARNING_ALERT:
      return {
        ...state,
        alertType: "warning",
        alertMessage: payload,
      };
    case INFO_ALERT:
      return {
        ...state,
        alertType: "info",
        alertMessage: payload,
      };

    default:
      return {
        ...state,
        alertType: null,
        alertMessage: null,
      };
  }
};

export default commonReducer;