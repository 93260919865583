import { FbBreadcrumbs, FbmakeStyles, FbTypeography } from "@flexibees/core";
import React from "react";
import { withRouter } from "react-router-dom";

const useStyles = FbmakeStyles({
  root: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "13px",
    color: "#000000",
    textTransform: "capitalize",
  },
  lastItem: {
    fontSize: "13px",
    textTransform: "capitalize",
  },
});

const breadcrumbItems = {
  projects: "All Projects",
  update: "Update Project Details",
  assign: "Assign Recruiter",
  view: "Recruitment Workflow",
  workflow: "Recruitment Workflow",
  detail: "Project Details",
  details: "Candidate Details",
  candidates: "All Candidates",
};

const hasNumber = (myString) => {
  return /\d/.test(myString);
};

const BreadCrumbs = (props) => {
  const { location, history } = props;
  const classes = useStyles();
  const { pathname } = location;
  const pathNames = pathname.split("/").filter((item) => item);

  const handleRoute = (route) => {
    if (breadcrumbItems[route]) {
      history.push(`/recruiter/${route}`);
    }
  };

  return (
    <FbBreadcrumbs separator=">">
      {pathNames.map((item, index) => {
        const lastItem = index === pathNames.length - 1;
        if (hasNumber(item) || item === "recruiter") {
          return null;
        }
        return (
          <FbTypeography
            onClick={() => handleRoute(item)}
            key={item}
            className={lastItem ? classes.lastItem : classes.root}
          >
            {breadcrumbItems[item] || item}
          </FbTypeography>
        );
      })}
    </FbBreadcrumbs>
  );
};

export default withRouter(BreadCrumbs);