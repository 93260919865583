import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FbTypeography,
  FbmakeStyles,
  FbIconButton,
  FbInput,
  FbGrid,
  FbButton,
  FbModal,
  FbMenuItem,
  FbTextField,
} from "@flexibees/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PropTypes from "prop-types";
import "antd/dist/antd.css";
import * as commonActions from "../../actions/commonAction";

const useStyles = FbmakeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    width: "35%",
    height: "auto",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    paddingBottom: theme.spacing(5),
    padding: theme.spacing(3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  marginHorizontal: {
    marginLeft: 15,
    marginRight: 15,
  },
  marginTop_25: {
    marginTop: 25,
  },
  textTransform: {
    textTransform: "none",
  },
  titleText: {
    marginLeft: 22,
  },
  formcontrol: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    width: "45%",
    height: 50,
    marginTop: 15,
  },
  tagstextfield: {
    height: 100,
  },
}));

export default function AddConfigurationModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    openModal,
    handleClose,
    handleSave,
    disabledtextInput,
    titleTag,
    addEditConfig,
  } = props;

  const [functionID, setfunctionID] = useState("select");
  const [configDropdownID, setconfigDropdownID] = useState("select_field");
  const functionData = useSelector((data) => data.config.functionData);
  const configDropdownData = useSelector(
    (state) => state.config.configDropdownData
  );

  const [disableTitle, setdisableTitle] = useState(disabledtextInput);

  const [state, setState] = useState({
    title: "",
    tags: "",
  });

  const handleMenuChange = (e) => {
    setfunctionID(e.target.value);
  };

  const handleConfigMenuChange = (e) => {
    let value = e.target.value;
    setconfigDropdownID(value);
    if (value !== "select_field") {
      setState({
        ...state,
        title: configDropdownData.find((item) => {
          return item.id === value;
        }).title,
      });
      setdisableTitle(true);
    } else {
      setState({
        ...state,
        title: "",
      });
      setdisableTitle(false);
    }
  };

  useEffect(() => {
    clearData();
  }, []);

  useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  const handleChange = (key) => (value) => {
    setState({ ...state, [key]: value });
  };

  const onCloseButtonClick = async () => {
    clearData();
    handleClose();
  };

  const [helperText, sethelperText] = useState(null);
  const [titleError, settitleError] = useState(false);
  const [tagError, settagError] = useState(false);

  const clearData = () => {
    setState({
      title: "",
      tags: "",
    });
    setfunctionID("select");
    setconfigDropdownID("select_field");
    settitleError(false);
    settagError(false);
    sethelperText(null);
  };

  const saveData = async () => {
    const saveData = state;
    const funID = functionID;
    const configListID = configDropdownID;

    // //console.log("savedata", configListID);

    if ((!saveData.title && !titleTag.title) || !saveData.tags) {
      if (functionID === "select" && titleTag?.title === "Skill") {
        dispatch(commonActions.errorAlert("Please select a function"));
        return;
      }
      if (!saveData.title && !titleTag.title) {
        settitleError(true);
        settagError(false);
        sethelperText("Enter the Title");
      } else {
        settitleError(false);
        settagError(true);
        sethelperText("Enter the Tags");
      }
    } else {
      settitleError(false);
      settagError(false);
      clearData();
      handleSave(saveData.title, saveData.tags, funID, configListID);
    }
  };

  return (
    <FbModal
      className={classes.modal}
      open={openModal}
      //   onClose={onCloseButtonClick}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <FbGrid container className={classes.paper}>
        <FbGrid item style={{ justifyContent: "flex-end" }} container>
          <FbIconButton size="small" item onClick={onCloseButtonClick}>
            <CloseIcon fontSize="small" />
          </FbIconButton>
        </FbGrid>
        <FbGrid item>
          <FbTypeography
            variant={"subtitle2"}
            className={[classes.textTransform, classes.titleText]}
          >
            Add New Tags
          </FbTypeography>
        </FbGrid>
        <FbGrid
          item
          className={[classes.marginTop_25, classes.marginHorizontal]}
        >
          <FbInput
            label={"Title"}
            fullWidth={true}
            isError={titleError}
            helperText={titleError ? helperText : null}
            value={titleTag.title ? titleTag.title : state.title}
            disabled={disableTitle}
            onChange={handleChange("title")}
          />
        </FbGrid>
        {addEditConfig && (
          <FbGrid
            item
            className={[classes.marginTop_25, classes.marginHorizontal]}
          >
            <FbTextField
              id="outlined-select-currency"
              select
              label={"Field Name to be Mapped to the Title"}
              fullWidth={true}
              value={configDropdownID}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                IconComponent: KeyboardArrowDownIcon,
              }}
              onChange={handleConfigMenuChange}
              variant="outlined"
            >
              <FbMenuItem key={"select_field"} value={"select_field"}>
                {"Select Field"}
              </FbMenuItem>
              {configDropdownData.map((item) => {
                return (
                  <FbMenuItem key={item.id} value={item.id}>
                    {item.title}
                  </FbMenuItem>
                );
              })}
            </FbTextField>
          </FbGrid>
        )}
        {titleTag.title === "Skill" ? (
          <FbGrid
            item
            className={[classes.marginTop_25, classes.marginHorizontal]}
          >
            <FbTextField
              id="outlined-select-currency"
              select
              label={"Function"}
              fullWidth={true}
              defaultValue={functionID}
              InputLabelProps={{
                shrink: true,
              }}
              SelectProps={{
                IconComponent: KeyboardArrowDownIcon,
              }}
              onChange={handleMenuChange}
              variant="outlined"
            >
              <FbMenuItem key={"select"} value={"select"}>
                Select
              </FbMenuItem>
              {functionData.map((item) => {
                return (
                  <FbMenuItem key={item.id} value={item.id}>
                    {item.name}
                  </FbMenuItem>
                );
              })}
            </FbTextField>
          </FbGrid>
        ) : null}
        <FbGrid
          item
          className={[classes.marginTop_25, classes.marginHorizontal]}
        >
          <FbInput
            label={"Tags"}
            value={state.tags}
            isError={tagError}
            helperText={tagError ? helperText : null}
            multiline
            rows={5}
            fullWidth={true}
            styles={{
              height: 100,
            }}
            onChange={handleChange("tags")}
          />
        </FbGrid>
        <FbGrid
          item
          style={{ justifyContent: "space-evenly" }}
          className={classes.marginTop_25}
          container
        >
          <FbButton
            item
            color="secondary"
            variant="contained"
            className={classes.button}
            onClick={onCloseButtonClick}
          >
            Close
          </FbButton>
          <FbButton
            item
            color="primary"
            variant="contained"
            className={classes.button}
            onClick={saveData}
          >
            Save
          </FbButton>
        </FbGrid>
      </FbGrid>
    </FbModal>
  );
}

AddConfigurationModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  disabledtextInput: PropTypes.bool,
  titleTag: PropTypes.string,
  addEditConfig: PropTypes.bool,
};
