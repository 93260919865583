import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "../components/utilities/loader";

const AppSettings = lazy(() =>
  import("../container/appSettings")
);

const AppSettingsRoute = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route path="/" component={AppSettings} />
      </Switch>
    </Suspense>
  );
};

export default AppSettingsRoute;
