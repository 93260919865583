import {
  GET_CONFIGURATION,
  GET_FUNCTIONS,
  SEARCH_CONFIGURATION,
  GET_CONFIG_DROPDOWN,
  CONFIG_LOADING,
  GET_ROLLS_SKILL_MAPPING
} from "../actions/configureAction";

const initialState = {
  configData: [],
  functionData: [],
  configDropdownData: [],
  rollskillMappingData: [],
};

const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIGURATION: {
      return {
        ...state,
        configData: action.payload,
        fetchingData: false,
      };
    }
    case GET_FUNCTIONS: {
      return {
        ...state,
        functionData: action.payload.data,
      };
    }
    case SEARCH_CONFIGURATION: {
      return {
        ...state,
        configData: action.payload,
        fetchingData: false,
      };
    }
    case GET_CONFIG_DROPDOWN: {
      return {
        ...state,
        configDropdownData: action.payload,
      };
    }
    case CONFIG_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    case GET_ROLLS_SKILL_MAPPING: {
      return {
       ...state,
       rollSkillMappingData: action.payload,
      };
    }
    default:
      return state;
  }
};

export default configReducer;