export const MAX_STACK_COUNT = 3;

export const NOT_APPLICABLE_FOR_LIST = [
  {
    id: "3_2_c_a",
    name: "I live with Parents",
  },
  {
    id: "3_2_c_b",
    name: "I live with Spouse",
  },
  {
    id: "3_2_c_c",
    name: "I live with Child",
  },
  {
    id: "3_2_c_d",
    name: "I live with Spouse and Child",
  },
  {
    id: "3_e",
    name: "I live with Others",
  },
  {
    id: "3_2_d_a",
    name: "I live with Paternal/ Maternal Relatives",
  },
  {
    id: "3_2_d_b",
    name: "I live with Spouse/ Maternal/ Paternal Relatives",
  },
  {
    id: "3_2_d_c",
    name: "I live with Child / Maternal/ Paternal Relatives",
  },
  {
    id: "3_2_d_d",
    name: "I live with Spouse and Child/ Maternal/ Paternal Relatives",
  },
];

export const AVAILABILITY_SESSIONS = [
  {
    id: "morning",
    label: "Morning",
  },
  {
    id: "afternoon",
    label: "Afternoon",
  },
  {
    id: "evening",
    label: "Evening",
  },
  {
    id: "night",
    label: "Night",
  },
];

export const SESSIONS_FILTER = [
  {
    id: "morning",
    tag_name: "Morning",
  },
  {
    id: "afternoon",
    tag_name: "Afternoon",
  },
  {
    id: "evening",
    tag_name: "Evening",
  },
  {
    id: "night",
    tag_name: "Night",
  },
];

export const FREETIME_FILTER = [
  {
    id: "yes",
    tag_name: "Yes",
  },
  {
    id: "no",
    tag_name: "No",
  },
];

export const AVAILABILITY_PRIORITY = [
  {
    id: "low",
    label: "Low",
  },
  {
    id: "medium",
    label: "Medium",
  },
  {
    id: "high",
    label: "High",
  },
];

export const LIFESTYLE_RESPONSES = [
  {
    id: 1,
    label: "Studying part time",
    value: "1_a",
  },
  {
    id: 2,
    label: "Studying full time",
    value: "1_b",
  },
  {
    id: 3,
    label: "Just graduated",
    value: "1_c",
  },
  {
    id: 4,
    label: "On a break",
    value: "1_d",
  },
  {
    id: 5,
    label: "Currently working full-time",
    value: "1_e",
  },
  {
    id: 6,
    label: "Currently working part-time",
    value: "1_f",
  },
  {
    id: 7,
    label: "Currently looking for work",
    value: "1_g",
  },
  {
    id: 9,
    label: "I live alone",
    value: "2_a",
  },
  {
    id: 10,
    label: "I live with friends",
    value: "2_b",
  },
  {
    id: 11,
    label: "I live in a nuclear family",
    value: "2_c",
  },
  {
    id: 12,
    label: "I live in a joint family",
    value: "2_d",
  },
  {
    id: 13,
    label: "Parents",
    value: "3_2_c_a",
  },
  {
    id: 14,
    label: "Spouse",
    value: "3_2_c_b",
  },
  {
    id: 15,
    label: "Child",
    value: "3_2_c_c",
  },
  {
    id: 16,
    label: "Spouse and Child",
    value: "3_2_c_d",
  },
  {
    id: 17,
    label: "Others",
    value: "3_e",
  },
  {
    id: 18,
    label: "Paternal/ Maternal Relatives",
    value: "3_2_d_a",
  },
  {
    id: 19,
    label: "Spouse/ Maternal/ Paternal Relatives",
    value: "3_2_d_b",
  },
  {
    id: 20,
    label: "Child / Maternal/ Paternal Relatives",
    value: "3_2_d_c",
  },
  {
    id: 21,
    label: "Spouse and Child/ Maternal/ Paternal Relatives",
    value: "3_2_d_d",
  },
  {
    id: 22,
    label: "Others",
    value: "3_e",
  },
  {
    id: 23,
    label: "My-self",
    value: "4_a",
  },
  {
    id: 24,
    label: "Part time help",
    value: "4_b",
  },
  {
    id: 25,
    label: "Full time help",
    value: "4_c",
  },
  {
    id: 26,
    label: "Full time live-in-help",
    value: "4_d",
  },
];

export const STATUS_FILTER = [
  {
    id: 1,
    tag_name: "Pending",
  },
  {
    id: 2,
    tag_name: "Approved",
  },
  {
    id: 3,
    tag_name: "Rejected",
  },
];
