import {
  SEARCH_SKILLS,
  SKILLS_LOADING,
  CHANGE_CURRENT_SKILLS_PAGE,
  FROM_SKILLS_LISTING,
  FILTER_STATUS_SKILLS,
  SET_SKILLS_ITEMS_PER_PAGE,
} from "../actions/customSkillsAction";

const initialSkillsState = {
  skillsSearchText: "",
  fetchingData: true,
  skillsCurrentPage: 1,
  skillsList: [],
  totalResults: null,
  pageIds: [],
  totalPages: null,
  appliedStatusFilter: [],
  fromSkillsResultsPerPage: 10,
};

function createArray(n) {
  return Array.from({ length: n }, (v, i) => i + 1);
}

const skillsReducer = (state = initialSkillsState, action) => {
  switch (action.type) {
    case FROM_SKILLS_LISTING: {
      return {
        ...state,
        skillsList: action.payload.results,
        totalResults: action.payload.total_results,
        totalPages: action.payload.total_pages,
        fetchingData: false,
        pageIds: createArray(action.payload.total_pages),
      };
    }
    case FILTER_STATUS_SKILLS: {
      return {
        ...state,
        appliedStatusFilter: action.payload,
      };
    }
    case SET_SKILLS_ITEMS_PER_PAGE: {
      return {
        ...state,
        fromSkillsResultsPerPage: action.payload,
      };
    }
    case SEARCH_SKILLS: {
      return {
        ...state,
        skillsSearchText: action.payload,
      };
    }
    case SKILLS_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    case CHANGE_CURRENT_SKILLS_PAGE: {
      return {
        ...state,
        skillsCurrentPage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default skillsReducer;