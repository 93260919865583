export const COLORS = {
  PRIMARY: "#F8B817",
  TEXT: "#222222",
  SUBTEXT: "#888888",
  GREY: "#DDDDDD",
  BLUE: "#2D7DDB",
  LIGHT_PINK: "#FFF4DA",
  BORDER: "lightgrey",
  TRANSPARENT: "transparent",
  GREEN: "#6AC155",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  FLEXIBEES_HONEY: "#F99202",
  LINK: "#2D9CDB",
};
