import { decryptData } from "@flexibees/core";
import axios from "axios";
import * as commonActions from "../actions/commonAction";
import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosMediaUpload,
  axiosPostWithToken,
  axiosPut,
} from "../services/apiServices";
import { ERROR_ALERT, INFO_ALERT, SUCCESS_ALERT } from "./commonAction";

export const VIEW_CANDIDATE_DETAILS = "VIEW_CANDIDATE_DETAILS";
export const GET_CANDIDATES_LIST = "GET_CANDIDATES_LIST";
export const GET_CANDIDATES_LIST_FILTERS = "GET_CANDIDATES_LIST_FILTERS";
export const CANDIDATE_LIST_LOADER = "CANDIDATE_LIST_LOADER";
export const SET_CANDIDATE_ITEMS_PER_PAGE = "SET_CANDIDATE_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_CANDIDATE_PAGE = "CHANGE_CURRENT_CANDIDATE_PAGE";
export const SEARCH_CANDIDATES = "SEARCH_CANDIDATES";
// filters const
export const SEARCH_CANDIDATES_FILTERS = "SEARCH_CANDIDATES_FILTERS";
export const RESET_SEARCH_CANDIDATES_FILTERS = "RESET_SEARCH_CANDIDATES_FILTERS";
export const CANDIDATE_LIST_CITY_FILTER = "CANDIDATE_LIST_CITY_FILTER";
export const CANDIDATE_LIST_SKILLS_FILTER = "CANDIDATE_LIST_SKILLS_FILTER";
export const CANDIDATE_LIST_ROLES_FILTER = "CANDIDATE_LIST_ROLES_FILTER";
export const CANDIDATE_LIST_YEARS_OF_EXP_FILTER =
  "CANDIDATE_LIST_YEARS_OF_EXP_FILTER";
export const CANDIDATE_LIST_YEARS_OF_BREAK_FILTER =
  "CANDIDATE_LIST_YEARS_OF_BREAK_FILTER";
export const CANDIDATE_LIST_FLEXIBEES_SELECTED_FILTER =
  "CANDIDATE_LIST_FLEXIBEES_SELECTED_FILTER";
export const CANDIDATE_LIST_ACTIVE_PROJECTS_FILTER =
  "CANDIDATE_LIST_ACTIVE_PROJECTS_FILTER";

export const CANDIDATE_LIST_MIN_TOTAL_EXP = "CANDIDATE_LIST_MIN_TOTAL_EXP";
export const CANDIDATE_LIST_MAX_TOTAL_EXP = "CANDIDATE_LIST_MAX_TOTAL_EXP";
export const CANDIDATE_LIST_FILTER_TOTAL_EXP =
  "CANDIDATE_LIST_FILTER_TOTAL_EXP";

export const CANDIDATE_CITY_LIST_LOADER = "CANDIDATE_CITY_LIST_LOADER";
export const GET_CANDIDATES_CITY_LIST = "GET_CANDIDATES_CITY_LIST";
export const SEARCH_CANDIDATES_CITY = "SEARCH_CANDIDATES_CITY";
export const SET_CANDIDATE_CITY_ITEMS_PER_PAGE =
  "SET_CANDIDATE_CITY_ITEMS_PER_PAGE";
export const CANDIDATE_LIST_AVAILABLE_HOURS_FILTER =
  "CANDIDATE_LIST_AVAILABLE_HOURS_FILTER";
export const VIEW_CANDIDATE_TIMELINE = "VIEW_CANDIDATE_TIMELINE";
export const VIEW_CANDIDATE_JOURNEY = "VIEW_CANDIDATE_JOURNEY";
export const VIEW_CANDIDATE_FEEDBACK = "VIEW_CANDIDATE_FEEDBACK";
export const RESET_CANDIDATE_JOURNEY = "RESET_CANDIDATE_JOURNEY";

export const getCandidateJourney = (id, openModal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const candidateId = decryptData(id);
    axiosGetWithToken(`${API.CANDIDATE_JOURNEY}/${candidateId}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_CANDIDATE_JOURNEY,
            payload: res.data.data,
          });
          if (res?.data?.data?.project_details.length > 0) {
            openModal(res?.data?.data?.project_details[0], id);
          } else {
            openModal(false);
          }
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const resetCandidateJourney = () => {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_CANDIDATE_JOURNEY,
    });
  };
};

export const getCandidateJourneyFeedback = (
  projectId,
  candidateId,
  openModal
) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const id = decryptData(candidateId);

    axiosGetWithToken(
      `${API.CANDIDATE_JOURNEY_FEEDBACK}/${projectId}/${id}/`,
      token
    )
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_CANDIDATE_FEEDBACK,
            payload: res.data.data,
          });
          openModal();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const downloadFlexibeesResume = (id, endDownload) => {
  return async (dispatch, getState) => {
    const candidateId = decryptData(id);
    const token = getState().auth.token;
    let config = {
      headers: { Authorization: token, "Content-Type": "application/pdf" },
      responseType: "blob",
    };

    axios
      .get(`${API.DOWNLOAD_FLEXIBEES_RESUME}/${candidateId}/`, config)
      .then((response) => {
        endDownload();
        let url = window.URL.createObjectURL(response.data);
        window.open(url);
      });
  };
};

export const uploadPicture = (file_type, file, updatePic) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosMediaUpload(API.MEDIA_UPLOAD, file_type, file, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          updatePic(res.data.data);
          dispatch({
            type: SUCCESS_ALERT,
            payload: "Image uploaded successfully",
          });
          dispatch({
            type: INFO_ALERT,
            payload: "Please Save to update your details.",
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const getCandidateTimeline = (id, toggleModal) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    axiosGetWithToken(`${API.GET_CANDIDATE_TIMELINE}/${id}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_CANDIDATE_TIMELINE,
            payload: res.data.data,
          });
          toggleModal();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const downloadCandidateTimeline = (id, endDownload) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    let config = {
      headers: { Authorization: token, "Content-Type": "application/pdf" },
      responseType: "blob",
    };

    axios
      .get(`${API.DOWNLOAD_CANDIDATE_TIMELINE}/${id}/`, config)
      .then((response) => {
        endDownload();
        let url = window.URL.createObjectURL(response.data);
        window.open(url);
      });
  };
};

export const viewCandidate = (id, disableLoader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const candidateId = decryptData(id);

    axiosGetWithToken(`${API.GET_CANDIDATE_DETAILS}/${candidateId}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_CANDIDATE_DETAILS,
            payload: res.data.data,
          });
          disableLoader();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const getCandidateList = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: CANDIDATE_LIST_LOADER,
    });
    const token = getState().auth.token;
    const pageSize = getState().candidates.candidatesListResultsPerPage;
    const page = getState().candidates.candidatesListCurrentPage;
    const searchText = getState().candidates.candidatesListSearchText;
    // filters list here such as role,skills,year of exp, years of break,flexibees selected,active projects
    const skills = getState().candidates.candidatesListApplaiedFilterSkills;
    const roles = getState().candidates.candidatesListApplaiedFilterRoles;
    const cities = getState().candidates.candidatesListApplaiedFilterCities;
    const years_of_experience =
      getState().candidates.candidatesListApplaiedFilterYearsOfExp;
    const years_of_break =
      getState().candidates.candidatesListApplaiedFilterYearsOfBreak;
    const flexibees_selected =
      getState().candidates.candidatesListApplaiedFilterFlexiSelected;
    const active_projects =
      getState().candidates.candidatesListApplaiedFilterActiveProjects;

    const minTotalExp =
      getState().candidates.candidateListAppliedMinTotalExpFilter;
    const maxTotalExp =
      getState().candidates.candidateListAppliedMaxTotalExpFilter;
    const total_available_hours =
      getState().candidates.candidatesListApplaiedFilterAvailableHours;
    const search_filters =
      getState().candidates.candidatesSearchAppliedFilters;

    const bodyData = {
      search_term: searchText,
      filter_data: {
        skills: skills,
        roles: roles,
        cities: cities,
        // years_of_experience: years_of_experience,
        years_of_break: years_of_break,
        flexibees_selected: flexibees_selected,
        active_projects: active_projects,
        years_of_experience: {
          min: minTotalExp,
          max: maxTotalExp,
        },
        total_available_hours: total_available_hours,
      },
      search_fields: search_filters,
    };
    axiosPostWithToken(
      `${API.GET_CANDIDATES}/${pageSize}/${page}/`,
      bodyData,
      token
    )
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_CANDIDATES_LIST,
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const getCandidateCityList = () => {
  return async (dispatch, getState) => {
    // dispatch({
    //   type: CANDIDATE_CITY_LIST_LOADER,
    // });
    const token = getState().auth.token;
    const pageSize = getState().candidates.candidatesCityFilterResultsPerPage;
    const searchCityText = getState().candidates.candidatesListCitySearchText;

    const bodyData = {
      search_term: searchCityText,
    };
    axiosPostWithToken(
      `${API.GET_CANDIDATES_CITY_LIST}/${pageSize}/${1}/`,
      bodyData,
      token
    )
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_CANDIDATES_CITY_LIST,
            payload: res.data.data.result.results,
            total: res.data.data.result.total_results,
            pages: res.data.data.result,
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const getCandidateFilters = () => {
  return async (dispatch, getState) => {
  
    const token = getState().auth.token;

    axiosGetWithToken(API.GET_CANDIDATES_FILTERS, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: GET_CANDIDATES_LIST_FILTERS,
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data)
        });
      });
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_LOADER,
    });
    dispatch({
      type: SET_CANDIDATE_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};
export const setCityItemsPerPage = () => {
  return async (dispatch, getState) => {
    const pageSize = getState().candidates.candidatesCityFilterResultsPerPage;
    const totalCities = getState().candidates.candidatesListCityTotalResults;
    if (pageSize === totalCities) {
      dispatch({
        type: ERROR_ALERT,
        payload: "No more cities to load",
      });
      return;
    } else {
      dispatch({
        type: SET_CANDIDATE_CITY_ITEMS_PER_PAGE,
        payload: pageSize + 30,
      });
    }
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_LOADER,
    });
    dispatch({
      type: CHANGE_CURRENT_CANDIDATE_PAGE,
      payload: value,
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_LOADER,
    });
    dispatch({
      type: SEARCH_CANDIDATES,
      payload: value,
    });
  };
};

export const searchCities = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_CITY_LIST_LOADER,
    });
    dispatch({
      type: SEARCH_CANDIDATES_CITY,
      payload: value,
    });
  };
};

// and some filters data set and get

export const searchFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SEARCH_CANDIDATES_FILTERS,
      payload: value,
    });
  };
};

export const resetSearchData = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_SEARCH_CANDIDATES_FILTERS,
    });
  };
};

export const setCitiesFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_LOADER,
    });
    dispatch({
      type: CANDIDATE_LIST_CITY_FILTER,
      payload: value,
    });
  };
};

export const setSkillsFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_SKILLS_FILTER,
      payload: value,
    });
  };
};

export const setRolesFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_ROLES_FILTER,
      payload: value,
    });
  };
};
export const setYearsOfExpFilter = (min, max) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_YEARS_OF_EXP_FILTER,
      payload: { min, max },
    });
  };
};

export const setAvailableHoursFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_AVAILABLE_HOURS_FILTER,
      payload: value,
    });
  };
};

export const setYearsOfBreakFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_YEARS_OF_BREAK_FILTER,
      payload: value,
    });
  };
};

export const setFlexibeesSelectedFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_FLEXIBEES_SELECTED_FILTER,
      payload: value,
    });
  };
};
export const setActiveProjectsFilter = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_ACTIVE_PROJECTS_FILTER,
      payload: value,
    });
  };
};

export const hireOrNotHire = (candidate_id, refresh) => {
  const candidateId = decryptData(candidate_id);

  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPut(`${API.HIRE_NOT_TO_HIRE}/${candidateId}/`, {}, token).then(
      (res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SUCCESS_ALERT,
            payload: res.data.data.message,
          });
          refresh();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: "Something went wrong. Unable to process request.",
          });
        }
      }
    );
  };
};

export const changeCandidateEmail = (bodyData, handleClose) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    axiosPostWithToken(
      `${API.CHANGE_CANDIDATE_EMAIL}`,
      bodyData,
      token
    )
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch(commonActions.successAlert(res.data.data.message));
          handleClose();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const changeMinTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_MIN_TOTAL_EXP,
      payload: value,
    });
  };
};

export const changeMaxTotalExp = (value) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_MAX_TOTAL_EXP,
      payload: value,
    });
  };
};

export const clearTotalExpFilter = (value, filterData) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_LOADER,
    });
    dispatch({
      type: CANDIDATE_LIST_MIN_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: CANDIDATE_LIST_MAX_TOTAL_EXP,
      payload: value,
    });
    dispatch({
      type: CANDIDATE_LIST_FILTER_TOTAL_EXP,
      payload: false,
    });
    filterData();
  };
};

export const filterTotalExp = (filterData) => {
  return async (dispatch) => {
    dispatch({
      type: CANDIDATE_LIST_LOADER,
    });
    dispatch({
      type: CANDIDATE_LIST_FILTER_TOTAL_EXP,
      payload: true,
    });
    filterData();
  };
};
