import { retrieveLocalStorage } from "@flexibees/core";
import {
  LOGIN,
  AUTHENTICATE,
  SET_DID_TRY_AL,
  LOGOUT,
  CHANGE_PASSWORD,
  USER_INFO,
  UPDATE_PROFILE,
  FORGOT_PASSWORD,
} from "../actions/authAction";

const initialState = {
  didTryAutoLogin: false,
  token: retrieveLocalStorage("token"),
  userData: retrieveLocalStorage("userData"),
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        token: action.payload.token,
        userData: action.payload.user,
      };
    }
    case USER_INFO: {
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
      };
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        userData: { ...state.userData, ...action.payload },
      };
    }
    case AUTHENTICATE: {
      return {
        ...state,
        token: action.token,
        userData: action.user,
        didTryAutoLogin: true,
      };
    }
    case SET_DID_TRY_AL:
      return {
        ...state,
        didTryAutoLogin: true,
      };
    case LOGOUT:
      return {
        token: action.token,
        userData: action.user,
      };
    case CHANGE_PASSWORD:
      return {
        ...state,
        token: action.payload.token,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default authReducer;
