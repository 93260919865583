import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import {
  FbInnerContainer,
  FbmakeStyles,
  FbIconButton,
  FbInput,
  FbTooltip,
  FbGrid,
  FbTypeography,
  FbBox,
} from "@flexibees/core";
import { Table, Space } from "antd";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";
import BackButton from "@material-ui/icons/ArrowBack";
import "antd/dist/antd.css";
import { COLORS } from "../../constants/colors";
import AddConfigurationModal from "../../components/configure/AddConfigurationModal";
import * as configureAction from "../../actions/configureAction";
import { getHighlightedText } from "../../utils/textHighlighter";

const useStyles = FbmakeStyles((theme) => ({
  contentHeader: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
  },
  contentBody: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  textTransform_none: {
    textTransform: "none",
  },
  tags: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  addConfigButton: {
    marginRight: 20,
  },
  marginTop_25: {
    marginTop: 25,
  },
  marginRight_20: {
    marginRight: 20,
  },
  subtextColor: {
    color: COLORS.SUBTEXT,
  },
  tableContainer: {},
  marginHorizontal_20: {
    marginLeft: 20,
    marginRight: 20,
  },
  rowTitle: {
    color: COLORS.SUBTEXT,
  },
}));

export default function FunctionRoleSkillMappingPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParameters = new URLSearchParams(window.location.search)
  const name = queryParameters.get("name")
  const id = queryParameters.get("id")
  const isLoading = useSelector((state) => state.config.fetchingData);

  const rollskillMappingData = useSelector((state) => state.config.rollSkillMappingData);

  const [showAddConfigModal, setshowAddConfigModal] = useState(false);
  const [disabletextInput, setDisabletextInput] = useState(false);
  const [showMoreTags, setshowMoreTags] = useState([]);
  const [titleTag, setTitleTag] = useState({});
  const [addEditConfig, setaddEditConfig] = useState(true);
  const [searchText, setsearchText] = useState("");

  const handleChange = (key) => async (value) => {
    setsearchText(value);
    if (value.length > 2) {
      dispatch(configureAction.search_rollskillMapping(value,id));
    }
  };

  useEffect(() => {
    if (searchText === undefined || searchText === "") {
      setsearchText("");
      dispatch(configureAction.get_rolls_skill_mapping(id));
    }
  }, [searchText]);

  const create_configutation = (title, tags, configListID) => {
    let tags_Array = tags.split(",");
    setTimeout(() => {
      dispatch(
        configureAction.create_configuration(
          title,
          tags_Array,
          configListID,
          refreshTable
        )
      );
    }, 200);
  };

  const add_Configuration = async (title, tags, functionID) => {
    let tags_Array = tags.split(",");
    switch (titleTag.title) {
      case "Function":
        await dispatch(
          configureAction.add_functionTag(tags_Array, refreshTable)
        );
        break;
      case "Skill":
        await dispatch(
          configureAction.add_skillTag(tags_Array, refreshTable, functionID)
        );
        break;
      case "Domain":
        await dispatch(configureAction.add_domainTag(tags_Array, refreshTable));
        break;
      case "Role":
        await dispatch(configureAction.add_roleTag(tags_Array, refreshTable));
        break;
      default:
        let array_exist_new = titleTag.tags
          .map(({ name }) => name)
          .concat(tags_Array);
        await dispatch(
          configureAction.add_otherTag(
            titleTag.id,
            array_exist_new,
            refreshTable
          )
        );
        break;
    }
  };

  const refreshTable = () => {
    setTitleTag({});
    setaddEditConfig(false);
    setshowAddConfigModal(false);
    dispatch(configureAction.get_configuration());
  };

  const addDeleteShowMoreTags = (id) => {
    let moreTags = showMoreTags;
    if (moreTags.includes(id)) {
      let index = moreTags.indexOf(id);
      if (index > -1) {
        moreTags.splice(index, 1);
      }
    } else {
      moreTags.push(id);
    }
    setshowMoreTags(moreTags);
  };

  const columns = [
    {
      title: () => (
        <div className={classes.tags}>
          <FbTypeography
            variant={"body1"}
            className={[classes.textTransform_none, classes.rowTitle]}
          >
            {"Role"}
          </FbTypeography>
        </div>
      ),
      dataIndex: "role",
      key: "role",
      width: 200,
      render: (role) => (
        <div className={classes.tags}>
          <FbTypeography
            variant={"body1"}
            className={[classes.textTransform_none]}
          >
            {searchText.length > 2
              ? getHighlightedText(role, searchText)
              : role}
          </FbTypeography>
        </div>
      ),
    },
    {
      title: () => (
        <div className={classes.tags}>
          <FbTypeography
            variant={"body1"}
            className={[classes.textTransform_none, classes.rowTitle]}
          >
            {"Skills"}
          </FbTypeography>
        </div>
      ),
      fixed: true,
      width: 800,
      dataIndex: "skills",
      key: "skills",
      render: (skill) => {
        return (
          <Space size={[20, 20]} wrap>
            {skill.length <= 0 ? (
              <FbTypeography
                variant={"caption"}
                className={[classes.textTransform_none]}
              >
                {`No Tags to display`}
              </FbTypeography>
            ) : null}
            {skill.slice(0, 5).map((tag) => (
              <FbTooltip
                disableHoverListener={tag && tag.tag_name !== ""}
              >
                <FbTypeography
                  variant={"caption"}
                  className={[classes.textTransform_none]}
                >
                  {searchText.length > 2
                    ? getHighlightedText(tag.tag_name,searchText)
                    : tag.tag_name}
                </FbTypeography>
              </FbTooltip>
            ))}
          </Space>
        );
      },
    },
  ];

  return (
    <FbInnerContainer
      contentBodyStyle={{ padding: 30 }}
      contentHeaderChildren={
        <FbGrid container className={classes.contentHeader}>
          <FbGrid item>
          <FbBox
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
          <FbIconButton onClick={() => history.goBack()}>
            <BackButton />
          </FbIconButton>
            <FbTypeography
              variant={"subtitle2"}
              className={classes.textTransform_none}
            >
              {name}
            </FbTypeography>
            </FbBox>
          </FbGrid>
        </FbGrid>
      }
    >
      <FbGrid container className={classes.contentBody}>
        <FbInput
          value={searchText}
          placeholder={"Search"}
          icon={SearchIcon}
          fullWidth={true}
          onChange={handleChange("searchText")}
        />
        <Table
          className={[classes.marginTop_25, classes.tableContainer]}
          style={{ width: "100%" }}
          tableLayout="auto"
          columns={columns}
          dataSource={rollskillMappingData}
          loading={isLoading}
          rowKey={(record) => {
            return record.id;
          }}
          pagination={false}
          size={"small"}
          scroll={{ x: 1000 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                addDeleteShowMoreTags(record.id);
              },
            };
          }}
          expandable={{
            expandRowByClick: false,
            expandedRowRender: (record) => (
              <FbGrid container>
                <FbGrid item style={{ paddingLeft: "13%" }}>
                  <Space size={[20, 20]} wrap>
                    {record.skills
                      .slice(5, record.skills.length)
                      .map((tag, index) => (
                        <FbTooltip
                          arrow
                          className={classes.textTransform_none}
                          // disableHoverListener={
                          //   record && record.title !== "Skill"
                          // }
                          // title={
                          //   <FbTypeography
                          //     variant={"caption"}
                          //     style={{ color: COLORS.WHITE }}
                          //     className={[classes.textTransform_none]}
                          //   >
                          //     {record.title === "Skill" && tag && tag.function}
                          //   </FbTypeography>
                          // }
                        >
                          <FbTypeography
                            variant={"caption"}
                            className={[classes.textTransform_none]}
                          >
                            {searchText.length > 2
                              ? getHighlightedText(tag.tag_name, searchText)
                              : tag.tag_name}
                          </FbTypeography>
                        </FbTooltip>
                      ))}
                  </Space>
                </FbGrid>
              </FbGrid>
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <ExpandLessIcon
                  onClick={(e) => {
                    addDeleteShowMoreTags(record.id);
                    onExpand(record, e);
                  }}
                />
              ) : (
                <ExpandMoreIcon
                  onClick={(e) => {
                    addDeleteShowMoreTags(record.id);
                    onExpand(record, e);
                  }}
                />
              ),
            rowExpandable: (record) => record.skills.length > 5,
            expandIconColumnIndex: 2,
          }}
        />
      </FbGrid>
      {showAddConfigModal && (
        <AddConfigurationModal
          openModal={showAddConfigModal}
          handleClose={() => {
            setTitleTag({});
            setaddEditConfig(false);
            setshowAddConfigModal(false);
          }}
          handleSave={(title, tags, functionID, configListID) => {
            if (disabletextInput) {
              add_Configuration(title, tags, functionID);
            } else {
              create_configutation(title, tags, configListID);
            }
          }}
          disabledtextInput={disabletextInput}
          titleTag={titleTag}
          addEditConfig={addEditConfig}
        />
      )}
    </FbInnerContainer>
  );
}