import { decryptData } from "@flexibees/core";
import { API } from "../constants/api";
import { axiosGetWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";

export const VIEW_PROJECT_DETAILS = "VIEW_PROJECT_DETAILS";
export const VIEW_PROJECT_INFO = "VIEW_PROJECT_INFO";

export const viewProject = (id, disableLoader) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const projectId = decryptData(id);

    axiosGetWithToken(`${API.VIEW_PROJECT}/${projectId}/`, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: VIEW_PROJECT_DETAILS,
            payload: res.data.data,
          });
          disableLoader();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const viewProjectInfo = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    axiosGetWithToken(`${API.VIEW_PROJECT_INFO}/${id}/`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: VIEW_PROJECT_INFO,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "Something went wrong.",
        });
      }
    });
  };
};
