import { API } from "../constants/api";
import { axiosPostWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const SET_NOTIFICATION_RESULTS_PERPAGE =
  "SET_NOTIFICATION_RESULTS_PERPAGE";
export const SET_NOTIFICATION_LOADER_ON_SCROLL =
  "SET_NOTIFICATION_LOADER_BOTTOM_SCROLL";
export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";

export const fetch_NotificationList = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().notification.notificationResultsPerPage;
    const page = getstate().notification.notificationCurrentPage;

    const body = {};

    axiosPostWithToken(
      `${API.GET_NOTIFICATION}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: FETCH_NOTIFICATIONS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch data from Zoho.",
        });
      }
    });
  };
};

export const getNotificationCount = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const ws = new WebSocket(`${API.NOTIFICATION_COUNT}/${token}/`);

    ws.onmessage = (data) => {
      dispatch({
        type: NOTIFICATION_COUNT,
        payload: JSON.parse(data.data).unread_count,
      });
    };
  };
};

export const set_Notification_Results = (value, callback) => {
  return async (dispatch, getstate) => {
    const pagesize = getstate().notification.notificationResultsPerPage;
    const notificationTotalResults = getstate().notification
      .notificationTotalResults;

    if (pagesize === notificationTotalResults) {
      return;
    } else if (value > notificationTotalResults) {
      dispatch({
        type: SET_NOTIFICATION_RESULTS_PERPAGE,
        payload: notificationTotalResults,
      });
      callback();
    } else {
      dispatch({ type: SET_NOTIFICATION_LOADER_ON_SCROLL });

      dispatch({ type: SET_NOTIFICATION_RESULTS_PERPAGE, payload: value });
      callback();
    }
  };
};

export const set_Notification_Loader_On_Scroll = () => ({
  type: SET_NOTIFICATION_LOADER_ON_SCROLL,
});
