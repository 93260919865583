import {
  LIST_DASHBOARD,
  LOAD_DASHBOARD_LIST,
  SEARCH_DASHBOARD_LIST,
  SET_DASHBOARD_ITEMS_PER_PAGE,
  CHANGE_CURRENT_DASHBOARD_PAGE,
} from "../actions/dashboardAction";

const initialState = {
  noOfVisitors: 0,
  registerdUsers: 0,
  dashboardList: [],
  dashboardSearchText: "",
  fetchingDashboardCardData: true,
  //Pagination
  dashboardListTotalResults: null,
  dashboardListCurrentPage: 1,
  dashboardListResultsPerPage: 5,
  dashboardListTotalPages: null,
  dashboardListPageIds: [],
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_DASHBOARD: {
      return {
        ...state,
        dashboardList: action.payload.users_data.results,
        dashboardListTotalResults: action.payload.users_data.total_results,
        dashboardListTotalPages: action.payload.users_data.total_pages,
        dashboardListPageIds: action.payload.users_data.page_ids,
        noOfVisitors: action.payload.no_of_visitors,
        registerdUsers: action.payload.registered_users,
        fetchingDashboardCardData: false,
      };
    }

    case LOAD_DASHBOARD_LIST: {
      return {
        ...state,
        fetchingDashboardCardData: true,
      };
    }

    case SEARCH_DASHBOARD_LIST: {
      return {
        ...state,
        dashboardSearchText: action.payload,
      };
    }

    case CHANGE_CURRENT_DASHBOARD_PAGE: {
      return {
        ...state,
        dashboardListCurrentPage: action.payload,
      };
    }

    case SET_DASHBOARD_ITEMS_PER_PAGE: {
      return {
        ...state,
        dashboardListResultsPerPage: action.payload,
      };
    }

    default:
      return state;
  }
};

export default dashboardReducer;
