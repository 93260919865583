export const data = [
  {
    id: 1,
    name: "Rashmi",
    role: "UI/UX Designer",
    status: "Flexibees Selected",
    updated: false,
    city: "Bangalore",
    years_of_experience: 3,
    years_of_break: 1,
    flexibees_selected: 1,
    active_projects: 2,
    skills: [
      {
        id: 1,
        name: "Wireframing",
        function: "Function1",
      },
      {
        id: 2,
        name: "Storyboarding",
        function: "Function2",
      },
      {
        id: 3,
        name: "Skill3",
        function: "Function3",
      },
      {
        id: 4,
        name: "Skill4",
        function: "Function4",
      },
    ],
  },
  {
    id: 2,
    name: "Meena",
    role: "UI/UX Designer",
    status: "Client Selected",
    updated: true,
    city: "Bangalore",
    years_of_experience: 3,
    years_of_break: 1,
    flexibees_selected: 1,
    active_projects: 2,
    skills: [
      {
        id: 1,
        name: "Wireframing",
        function: "Function1",
      },
      {
        id: 2,
        name: "Storyboarding",
        function: "Function2",
      },
      {
        id: 3,
        name: "Skill3",
        function: "Function3",
      },
      {
        id: 4,
        name: "Skill4",
        function: "Function4",
      },
    ],
  },
  {
    id: 3,
    name: "Shreya",
    role: "UI/UX Designer",
    status: "Client Rejected",
    updated: true,
    city: "Bangalore",
    years_of_experience: 3,
    years_of_break: 1,
    flexibees_selected: 1,
    active_projects: 2,
    skills: [
      {
        id: 1,
        name: "Wireframing",
        function: "Function1",
      },
      {
        id: 2,
        name: "Storyboarding",
        function: "Function2",
      },
      {
        id: 3,
        name: "Skill3",
        function: "Function3",
      },
      {
        id: 4,
        name: "Skill4",
        function: "Function4",
      },
    ],
  },
];

export const nameFilter = [
  {
    id: 1,
    tag_name: "Rashmi",
  },
  {
    id: 2,
    tag_name: "Meena",
  },
  {
    id: 3,
    tag_name: "Shreya",
  },
];

export const jobFilter = [
  {
    id: 1,
    tag_name: "UX/UI Designer",
  },
];
