import {
  ROLES_LOADING,
  SEARCH_ROLES,
  CHANGE_CURRENT_ROLES_PAGE,
  FROM_ROLES_LISTING,
  FILTER_STATUS_ROLES,
  SET_ROLES_ITEMS_PER_PAGE,
} from "../actions/customRolesAction";

const initialRolesState = {
  rolesSearchText: "",
  fetchingData: true,
  rolesCurrentPage: 1,
  rolesList: [],
  totalResults: null,
  totalPages: null,
  pageIds: [],
  appliedStatusFilter: [],
  fromRolesResultsPerPage: 10,
};

function createArray(n) {
  return Array.from({ length: n }, (v, i) => i + 1);
}

const rolesReducer = (state = initialRolesState, action) => {
  switch (action.type) {
    case FROM_ROLES_LISTING: {
      return {
        ...state,
        rolesList: action.payload.results,
        totalResults: action.payload.total_results,
        totalPages: action.payload.total_pages,
        fetchingData: false,
        pageIds: createArray(action.payload.total_pages),
      };
    }
    case FILTER_STATUS_ROLES: {
      return {
        ...state,
        appliedStatusFilter: action.payload,
      };
    }
    case SET_ROLES_ITEMS_PER_PAGE: {
      return {
        ...state,
        fromRolesResultsPerPage: action.payload,
      };
    }
    case SEARCH_ROLES: {
      return {
        ...state,
        rolesSearchText: action.payload,
      };
    }
    case ROLES_LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    case CHANGE_CURRENT_ROLES_PAGE: {
      return {
        ...state,
        rolesCurrentPage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default rolesReducer;