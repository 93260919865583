const BaseURL = "https://flexibees.appinessworld.com/api/admin";
const BaseURLForMapping = "https://flexibees.appinessworld.com/api";
const SocketURL = "ws://flexibees.appinessworld.com/ws";
const EditURL = "https://flexibees.appinessworld.com/api"

export const API = {
  LOGIN: `${BaseURL}/login/`,
  CHANGE_PASSWORD: `${BaseURL}/change-password/`,
  FORGOT_PASSWORD: `${BaseURL}/forgot-password/`,
  //CONFIGURATION
  GET_CONFIGURATION: `${BaseURL}/configuration/`,
  CREATE_CONFIGURATION: `${BaseURL}/configuration/`,
  ADD_FUNCTIONTAG: `${BaseURL}/function/`,
  GET_FUNCTIONS: `${BaseURL}/function/`,
  GET_CONFIG_DROPDOWN: `${BaseURL}/config-dropdowns/`,
  ADD_SKILLTAG: `${BaseURL}/skill/`,
  ADD_DOMAINTAG: `${BaseURL}/domain/`,
  ADD_ROLETAG: `${BaseURL}/role/`,
  SEARCH_CONFIGURATION: `${BaseURL}/configuration-search/`,
  ADD_OTHERTAG: `${BaseURL}/configuration/`,
  GET_ROLLS_SKILL_MAPPING: `${BaseURLForMapping}/common/roles-skill`,
  GET_CUSTOM_ROLES_LISTING: `${BaseURLForMapping}/common/custom-role-list/`,
  GET_CUSTOM_SKILLS_LISTING: `${BaseURLForMapping}/common/custom-skill-list/`,
  ACCEPT_CUSTOM_ROLE: `${BaseURLForMapping}/common/approve-role-status/update/`,
  ACCEPT_CUSTOM_SKILL: `${BaseURLForMapping}/common/approve-skills-status/update/`,
  SET_EDITED_ROLES: `${EditURL}/common/custom-role/update/`,
  SET_EDITED_SKILLS: `${EditURL}/common/custom-skill/update/`,

  GET_USERS: `${BaseURL}/admin-list`,
  SEARCH_USERS: `${BaseURL}/search-admin`,
  ADD_USER: `${BaseURL}/add-admin/`,
  GET_SKILLS: `${BaseURL}/skill/`,

  MEDIA_UPLOAD: `${BaseURL}/media-upload/`,
  VIEW_PROFILE: `${BaseURL}/view-profile/`,
  EDIT_PROFILE: `${BaseURL}/edit-profile/`,
  EDIT_USER: `${BaseURL}/edit-admin`,
  DELETE_USER: `${BaseURL}/delete-admin`,
  GET_NOTIFICATION: `${BaseURL}/notification`,
  NOTIFICATION_COUNT: `${SocketURL}/admin-notification`,

  GET_CANDIDATES: `${BaseURL}/all-candidates-list`,
  GET_CANDIDATES_FILTERS: `${BaseURL}/all-candidates-filters/`,
  GET_CANDIDATE_DETAILS: `${BaseURL}/candidate-detail`,
  CHANGE_CANDIDATE_EMAIL: `${BaseURL}/change-email/`,
  HIRE_NOT_TO_HIRE: `${BaseURL}/hire-not-hire-candidate`,
  GET_CANDIDATES_CITY_LIST: `${BaseURL}/all-candidates-city-list`,
  GET_CANDIDATE_TIMELINE: `${BaseURL}/candidate-timeline`,
  DOWNLOAD_CANDIDATE_TIMELINE: `${BaseURL}/candidate-timeline-download`,
  LIST_ACTIVITY_CARDS: `${BaseURL}/list-activity-card`,
  ADD_ACTIVITY_CARD: `${BaseURL}/add-activity-card/`,
  EDIT_ACTIVITY_CARD: `${BaseURL}/update-activity-card`,
  WEB_USERS_LIST: `${BaseURL}/web-users-list`,
  DOWNLOAD_FLEXIBEES_RESUME: `${BaseURL}/candidate-resume-download`,
  APP_INFO: `${BaseURL}/app-info/`,
  CANDIDATE_JOURNEY: `${BaseURL}/candidate-journey`,
  CANDIDATE_JOURNEY_FEEDBACK: `${BaseURL}/candidate-feedback-list`,
  VIEW_PROJECT: `${BaseURL}/view-project`,
  VIEW_PROJECT_INFO: `${BaseURL}/project-info`,
};
