import { API } from "../constants/api";
import { axiosGetWithToken, axiosPostWithToken } from "../services/apiServices";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";

export const SEARCH_SKILLS = "SEARCH_SKILLS";
export const SKILLS_LOADING = "SKILLS_LOADING";
export const CHANGE_CURRENT_SKILLS_PAGE = "CHANGE_CURRENT_SKILLS_PAGE";
export const FROM_SKILLS_LISTING = "FROM_SKILLS_LISTING";
export const FILTER_STATUS_SKILLS = "FILTER_STATUS_SKILLS";
export const SET_SKILLS_ITEMS_PER_PAGE = "SET_SKILLS_ITEMS_PER_PAGE";

export const fromCustomSkills = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().customSkills.fromSkillsResultsPerPage;
    const page = getstate().customSkills.skillsCurrentPage;
    const seachText = getstate().customSkills.skillsSearchText;
    const filterStatus = getstate().customSkills.appliedStatusFilter;

    axiosGetWithToken(
      `${API.GET_CUSTOM_SKILLS_LISTING}?search_terms=${seachText ? seachText : ''}&filter_status=${filterStatus ? filterStatus : ''}&page=${page ? page : ''}&page_size=${pagesize ? pagesize : ''}`,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: FROM_SKILLS_LISTING,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch data from Zoho.",
        });
      }
    });
  };
};

export const search = (value) => {
    return async (dispatch) => {
      dispatch({
        type: SKILLS_LOADING,
      });
      dispatch({
        type: SEARCH_SKILLS,
        payload: value,
      });
    };
};

export const changeCurrentPage = (value) => {
    return async (dispatch) => {
      dispatch({
        type: SKILLS_LOADING,
      });
      dispatch({
        type: CHANGE_CURRENT_SKILLS_PAGE,
        payload: value,
      });
    };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_SKILLS_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const filterSkillsStatus = (status) => {
  return async (dispatch) => {
    dispatch({
      type: FILTER_STATUS_SKILLS,
      payload: status,
    });
  };
};

export const accept_customSkill = (skillData, handleClose, action_type) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    try {
      const res = await axiosPostWithToken(`${API.ACCEPT_CUSTOM_SKILL}`, skillData, token);

      if (res.data.status_code === 200) {
        handleClose();
        dispatch(fromCustomSkills());
        dispatch({
          type: SUCCESS_ALERT,
          payload: `Custom Skill was ${action_type === 'accept' ? 'accepted' : 'rejected'} successfully!!`,
        });
      } else {
        const customErrorMessage = res?.data?.data?.errors?.[0];
        const defaultErrorMessage = "Unable to fetch Custom Skills!! Please try again.";
        dispatch({
          type: ERROR_ALERT,
          payload: customErrorMessage || defaultErrorMessage,
        });
      }
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: "Unable to fetch Custom Skills!! Please try again.",
      });
    }
  };
};


export const edited_customSkill = (skillData, handleClose, action_type) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    try {
      const res = await axiosPostWithToken(`${API.SET_EDITED_SKILLS}`, skillData, token);

      if (res.data.status_code === 200) {
        handleClose();
        dispatch(fromCustomSkills());
        dispatch({
          type: SUCCESS_ALERT,
          payload: `Custom Skill was ${action_type === 'accept' ? 'Updated' : 'rejected'} successfully!!`,
        });
      } else if (res.data.status_code === 400) {
        // Display custom error message for 400 status code
        dispatch({
          type: ERROR_ALERT,
          payload: res?.data?.data?.message || "Unable to fetch Custom Skills!! Please try again.",
        });
      } else {
        // Handle other status codes or errors
        dispatch({
          type: ERROR_ALERT,
          payload: "Unable to fetch Custom Skills!! Please try again.",
        });
      }
    } catch (error) {
      dispatch({
        type: ERROR_ALERT,
        payload: "Unable to fetch Custom Skills!! Please try again.",
      });
    }
  };
};
