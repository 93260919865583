import {
  FETCH_NOTIFICATIONS,
  SET_NOTIFICATION_RESULTS_PERPAGE,
  SET_NOTIFICATION_LOADER_ON_SCROLL,
  NOTIFICATION_COUNT,
} from "../actions/notificationActions";

const initialState = {
  notificationData: null,
  notificationCurrentPage: 1,
  notificationTotalPages: [],
  notificationResultsPerPage: 3,
  notificationTotalResults: null,
  notificationLoader: true,
  notificationCount: 0,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return {
        ...state,
        notificationData: action.payload.results,

        notificationCurrentPage: action.payload.current_page,
        notificationTotalPages: action.payload.total_pages,
        notificationTotalResults: action.payload.total_results,
        notificationLoader: false,
      };
    case SET_NOTIFICATION_RESULTS_PERPAGE:
      return {
        ...state,
        notificationResultsPerPage: action.payload,
      };
    case SET_NOTIFICATION_LOADER_ON_SCROLL:
      return {
        ...state,
        notificationLoader: true,
      };
    case NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;
