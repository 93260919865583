import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPatchWithToken,
  axiosDelete,
} from "../services/apiServices";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";

export const GET_USERS = "GET_USERS";
export const SEARCH_USERS = "SEARCH_USERS";
export const STORE_USER_DETAILS = "STORE_USER_DETAILS";
export const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_PAGE = "CHANGE_CURRENT_PAGE";
export const CHANGE_ROLE = "CHANGE_ROLE";
export const GET_SKILLS = "GET_SKILLS";
export const GET_FUNCTIONSS = "GET_FUNCTIONSS";
export const LOADING = "LOADING";
export const STORE_LEVEL_FILTER = "STORE_LEVEL_FILTER";
export const STORE_SKILLS_FILTER = "STORE_SKILLS_FILTER";
export const STORE_FUNCTIONS_FILTER = "STORE_FUNCTIONS_FILTER";
export const RESET_FILTER = "RESET_FILTER";
export const SEARCH_USER_MANAGEMENT = "SEARCH_USER_MANAGEMENT";
export const ACTIVE_PROJECTS_FILTER = "ACTIVE_PROJECTS_FILTER";

export const getUsers = () => {
  return async (dispatch, getstate) => {
    const role = getstate().users.role;
    const token = getstate().auth.token;
    const pagesize = getstate().users.resultsPerPage;
    const page = getstate().users.currentPage;
    const searchText = getstate().users.userSearchText;
    const level = getstate().users.filteredLevel;
    const skills = getstate().users.filteredSkills;
    const functions = getstate().users.filteredFunctions;
    const activeProjects = getstate().users.activeAppliedProjectFilter;

    const body = {
      search_term: searchText,
      filter_data: {
        level: level,
        skills: skills,
        functions: functions,
        active_projects: activeProjects,
      },
    };

    axiosPostWithToken(
      `${API.SEARCH_USERS}/${role}/${pagesize}/${page}/`,
      body,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_USERS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload: "Something went wrong. Error fetching data.",
        });
      }
    });
  };
};

export const setFilteredLevel = (level) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: STORE_LEVEL_FILTER,
      payload: level,
    });
  };
};

export const setFilteredSkills = (skills) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: STORE_SKILLS_FILTER,
      payload: skills,
    });
  };
};

export const setFilteredFunctions = (functions) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: STORE_FUNCTIONS_FILTER,
      payload: functions,
    });
  };
};

export const addUser = (data, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPostWithToken(API.ADD_USER, data, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: LOADING,
        });
        refresh();
        dispatch({
          type: SUCCESS_ALERT,
          payload: "User added Successfully",
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message || "Something went wrong. Error adding User.",
        });
      }
    });
  };
};

export const editUser = (id, data, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPatchWithToken(`${API.EDIT_USER}/${id}/`, data, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: LOADING,
        });
        refresh();
        dispatch({
          type: SUCCESS_ALERT,
          payload: "User updated Successfully",
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Error updating User.",
        });
      }
    });
  };
};

export const deleteUser = (id, refresh) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosDelete(`${API.DELETE_USER}/${id}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: LOADING,
        });
        refresh();
        dispatch({
          type: SUCCESS_ALERT,
          payload: "User deleted Successfully",
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Error deleting User.",
        });
      }
    });
  };
};

export const resetFilter = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_FILTER,
      payload: [],
    });
  };
};

export const storeUser = (details) => {
  return async (dispatch) => {
    dispatch({
      type: STORE_USER_DETAILS,
      payload: details,
    });
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: SET_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: CHANGE_CURRENT_PAGE,
      payload: value,
    });
  };
};

export const changeRole = (role) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: CHANGE_ROLE,
      payload: role,
    });
  };
};

export const getSkills = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.GET_SKILLS}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_SKILLS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch skill list.",
        });
      }
    });
  };
};

export const getFunctions = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.GET_FUNCTIONS}`, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_FUNCTIONSS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch function tags.",
        });
      }
    });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOADING,
    });
    dispatch({
      type: SEARCH_USER_MANAGEMENT,
      payload: value,
    });
  };
};

export const setActiveProjectsFilter = (activeProjects) => {
  return async (dispatch) => {
    dispatch({
      type: ACTIVE_PROJECTS_FILTER,
      payload: activeProjects,
    });
  };
};
