export const ALERT_COLORS = {
  SUCCESS: "#EFF7EE",
  SUCCESS_TEXT: "#74B369",
  INFO: "#EAF4FC",
  INFO_TEXT: "#569FEE",
  WARNING: "#FDF4E7",
  WARNING_TEXT: "#F3A549",
  ERROR: "#FAEDEB",
  ERROR_TEXT: "#E46152",
};
