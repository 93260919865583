import {
  CANDIDATE_CITY_LIST_LOADER,
  CANDIDATE_LIST_ACTIVE_PROJECTS_FILTER,
  CANDIDATE_LIST_AVAILABLE_HOURS_FILTER,
  CANDIDATE_LIST_CITY_FILTER,
  CANDIDATE_LIST_FILTER_TOTAL_EXP,
  CANDIDATE_LIST_FLEXIBEES_SELECTED_FILTER,
  CANDIDATE_LIST_LOADER,
  CANDIDATE_LIST_MAX_TOTAL_EXP,
  CANDIDATE_LIST_MIN_TOTAL_EXP,
  CANDIDATE_LIST_ROLES_FILTER,
  CANDIDATE_LIST_SKILLS_FILTER,
  CANDIDATE_LIST_YEARS_OF_BREAK_FILTER,
  CANDIDATE_LIST_YEARS_OF_EXP_FILTER,
  CHANGE_CURRENT_CANDIDATE_PAGE,
  GET_CANDIDATES_CITY_LIST,
  GET_CANDIDATES_LIST,
  GET_CANDIDATES_LIST_FILTERS,
  SEARCH_CANDIDATES,
  SEARCH_CANDIDATES_FILTERS,
  RESET_SEARCH_CANDIDATES_FILTERS,
  SEARCH_CANDIDATES_CITY,
  SET_CANDIDATE_CITY_ITEMS_PER_PAGE,
  SET_CANDIDATE_ITEMS_PER_PAGE,
  VIEW_CANDIDATE_DETAILS,
  VIEW_CANDIDATE_FEEDBACK,
  VIEW_CANDIDATE_JOURNEY,
  VIEW_CANDIDATE_TIMELINE,
  RESET_CANDIDATE_JOURNEY,
} from "../actions/candidateAction";
import { data } from "../container/candidates/candidatesBody/demoData";

const initialState = {
  candidatInfo: {},
  candidateList: [],
  activityCardList: [],
  candidateTimeline: {},
  candidatesListSearchText: "",
  candidatesListCitySearchText: "",
  candidatesListTotalResults: null,
  candidatesListCityTotalResults: null,
  candidatesListCityPages: null,
  candidatesListCurrentPage: 1,
  candidatesListResultsPerPage: 10,
  candidatesCityFilterResultsPerPage: 10,
  candidatesListTotalPages: null,
  candidatesListPageIds: [],
  fetchingData: true,
  fetchingCityData: true,

  // filter default data
  candidatesListFilterSkills: [],
  candidatesListFilterRoles: [],
  candidatesListFilterCities: [],
  candidatesListFilterYearsOfExp: [],
  candidatesListFilterYearsOfBreak: [],
  candidatesListFilterFlexiSelected: [],
  candidatesListFilterActiveProjects: [],
  candidatesListFilterAvailableHours: [],
  // applaid filter

  candidatesSearchAppliedFilters: [],
  candidatesListApplaiedFilterSkills: [],
  candidatesListApplaiedFilterRoles: [],
  candidatesListApplaiedFilterCities: [],
  candidatesListApplaiedFilterYearsOfExp: {},
  candidatesListApplaiedFilterYearsOfBreak: [],
  candidatesListApplaiedFilterFlexiSelected: [],
  candidatesListApplaiedFilterActiveProjects: [],
  candidatesListApplaiedFilterAvailableHours: [],

  candidateListAppliedTotalExpFilterState: false,
  candidateListAppliedMinTotalExpFilter: null,
  candidateListAppliedMaxTotalExpFilter: null,

  candidateJourney: {},
  candidateJourneyFeedback: [],
};

const candidateReducer = (state = initialState, action) => {
  //console.log("ACTION PAYLOAD", action.type, action.payload);
  switch (action.type) {
    case VIEW_CANDIDATE_FEEDBACK: {
      return {
        ...state,
        candidateJourneyFeedback: action.payload,
      };
    }
    case VIEW_CANDIDATE_JOURNEY: {
      return {
        ...state,
        candidateJourney: action.payload,
      };
    }
    case VIEW_CANDIDATE_DETAILS: {
      return {
        ...state,
        candidatInfo: action.payload || data,
      };
    }
    case RESET_CANDIDATE_JOURNEY: {
      //console.log("reducer called");
      return {
        ...state,
        candidateJourney: {},
        candidateJourneyFeedback: [],
      };
    }
    case GET_CANDIDATES_LIST: {
      return {
        ...state,
        candidateList: action.payload.result.results,
        candidatesListTotalResults: action.payload.result.total_results,
        candidatesListTotalPages: action.payload.result.total_pages,
        candidatesListPageIds: action.payload.result.page_ids,
        fetchingData: false,
      };
    }
    case GET_CANDIDATES_LIST_FILTERS: {
      return {
        ...state,
        // filters here
        candidatesListFilterSkills: action?.payload?.skills || [],
        candidatesListFilterRoles: action?.payload?.roles || [],
        candidatesListFilterYearsOfExp: action?.payload?.years_of_experience || {},
        candidatesListFilterYearsOfBreak: action?.payload?.years_of_break || [],
        candidatesListFilterFlexiSelected: action?.payload?.flexibees_selected || [],
        candidatesListFilterActiveProjects: action?.payload?.active_projects || [],
        candidatesListFilterAvailableHours: action?.payload?.total_available_hours || [],
      };
    }
    case VIEW_CANDIDATE_TIMELINE: {
      return {
        ...state,
        candidateTimeline: action.payload,
      };
    }

    case GET_CANDIDATES_CITY_LIST: {
      return {
        ...state,
        candidatesListFilterCities: action.payload || [],
        candidatesListCityTotalResults: action.total,
        candidatesListCityPages: action.pages,
        fetchingCityData: false,
      };
    }
    case CHANGE_CURRENT_CANDIDATE_PAGE: {
      return {
        ...state,
        candidatesListCurrentPage: action.payload,
      };
    }
    case SET_CANDIDATE_ITEMS_PER_PAGE: {
      return {
        ...state,
        candidatesListResultsPerPage: action.payload,
      };
    }
    case SET_CANDIDATE_CITY_ITEMS_PER_PAGE: {
      return {
        ...state,
        candidatesCityFilterResultsPerPage: action.payload,
      };
    }
    case SEARCH_CANDIDATES: {
      return {
        ...state,
        candidatesListSearchText: action.payload,
      };
    }
    case SEARCH_CANDIDATES_FILTERS: {
      return {
        ...state,
        candidatesSearchAppliedFilters: action.payload,
      };
    }
    case RESET_SEARCH_CANDIDATES_FILTERS: {
      return{
        ...state,
        candidatesSearchAppliedFilters: [],
      }
    }
    case SEARCH_CANDIDATES_CITY: {
      return {
        ...state,
        candidatesListCitySearchText: action.payload,
      };
    }
    case CANDIDATE_LIST_LOADER: {
      return {
        ...state,
        fetchingData: true,
      };
    }
    case CANDIDATE_CITY_LIST_LOADER: {
      return {
        ...state,
        fetchingCityData: true,
      };
    }
    case CANDIDATE_LIST_CITY_FILTER: {
      return {
        ...state,
        candidatesListApplaiedFilterCities: action.payload,
      };
    }
    case CANDIDATE_LIST_ROLES_FILTER: {
      return {
        ...state,
        candidatesListApplaiedFilterRoles: action.payload,
      };
    }
    case CANDIDATE_LIST_SKILLS_FILTER: {
      return {
        ...state,
        candidatesListApplaiedFilterSkills: action.payload,
      };
    }
    case CANDIDATE_LIST_YEARS_OF_EXP_FILTER: {
      return {
        ...state,
        candidatesListApplaiedFilterYearsOfExp: action.payload,
      };
    }
    case CANDIDATE_LIST_YEARS_OF_BREAK_FILTER: {
      return {
        ...state,
        candidatesListApplaiedFilterYearsOfBreak: action.payload,
      };
    }
    case CANDIDATE_LIST_AVAILABLE_HOURS_FILTER: {
      return {
        ...state,
        candidatesListApplaiedFilterAvailableHours: action.payload,
      };
    }
    case CANDIDATE_LIST_FLEXIBEES_SELECTED_FILTER: {
      return {
        ...state,
        candidatesListApplaiedFilterFlexiSelected: action.payload,
      };
    }
    case CANDIDATE_LIST_ACTIVE_PROJECTS_FILTER: {
      return {
        ...state,
        candidatesListApplaiedFilterActiveProjects: action.payload,
      };
    }

    case CANDIDATE_LIST_MIN_TOTAL_EXP: {
      return {
        ...state,
        candidateListAppliedMinTotalExpFilter: action.payload,
      };
    }

    case CANDIDATE_LIST_MAX_TOTAL_EXP: {
      return {
        ...state,
        candidateListAppliedMaxTotalExpFilter: action.payload,
      };
    }
    case CANDIDATE_LIST_FILTER_TOTAL_EXP: {
      return {
        ...state,
        candidateListAppliedTotalExpFilterState: action.payload,
      };
    }

    default:
      return state;
  }
};

export default candidateReducer;
