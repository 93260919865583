import { API } from "../constants/api";
import { axiosPostWithToken } from "../services/apiServices";
import { ERROR_ALERT } from "./commonAction";

export const LIST_DASHBOARD = "LIST_DASHBOARD";
export const LOAD_DASHBOARD_LIST = "LOAD_DASHBOARD_LIST";
export const SEARCH_DASHBOARD_LIST = "SEARCH_DASHBOARD_LIST";
export const SET_DASHBOARD_ITEMS_PER_PAGE = "SET_DASHBOARD_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_DASHBOARD_PAGE = "CHANGE_CURRENT_DASHBOARD_PAGE";

export const getDashboardList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const dashboardSearchText = getState().dashboard.dashboardSearchText;

    const pageSize = getState().dashboard.dashboardListResultsPerPage;
    const page = getState().dashboard.dashboardListCurrentPage;

    const bodyData = {
      search_term: dashboardSearchText,
      filter_data: {},
    };

    axiosPostWithToken(
      `${API.WEB_USERS_LIST}/${pageSize}/${page}/`,
      bodyData,
      token
    )
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: LIST_DASHBOARD,
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_DASHBOARD_LIST,
    });
    dispatch({
      type: SEARCH_DASHBOARD_LIST,
      payload: value,
    });
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_DASHBOARD_LIST,
    });
    dispatch({
      type: SET_DASHBOARD_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_DASHBOARD_LIST,
    });
    dispatch({
      type: CHANGE_CURRENT_DASHBOARD_PAGE,
      payload: value,
    });
  };
};
