import React from "react";
import { FbmakeStyles } from "@flexibees/core";
import { SnackbarContent } from "notistack";
import { ALERT_COLORS } from "../../constants/alertColors";
import errorIcon from "../../assets/error_alert.svg";
import successIcon from "../../assets/success_alert.svg";
import infoIcon from "../../assets/info_alert.svg";
import warnIcon from "../../assets/warn_alert.svg";

const useStyles = FbmakeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "344px !important",
    },
  },
  card: {
    width: "100%",
    padding: 12,
    borderRadius: 7,
    display: "flex",
    alignItems: "center",
  },
  typography: {
    fontWeight: 700,
    fontFamily: "Manrope",
    marginLeft: 10,
    fontSize: 12,
  },
  icon: {
    width: 20,
  },
}));

const getAlertBg = (variant) => {
  if (variant === "success") {
    return ALERT_COLORS.SUCCESS;
  }
  if (variant === "error") {
    return ALERT_COLORS.ERROR;
  }
  if (variant === "warning") {
    return ALERT_COLORS.WARNING;
  }
  if (variant === "info") {
    return ALERT_COLORS.INFO;
  }
};

const getAlertText = (variant) => {
  if (variant === "success") {
    return ALERT_COLORS.SUCCESS_TEXT;
  }
  if (variant === "error") {
    return ALERT_COLORS.ERROR_TEXT;
  }
  if (variant === "warning") {
    return ALERT_COLORS.WARNING_TEXT;
  }
  if (variant === "info") {
    return ALERT_COLORS.INFO_TEXT;
  }
};

const getAlertIcon = (variant) => {
  if (variant === "success") {
    return successIcon;
  }
  if (variant === "error") {
    return errorIcon;
  }
  if (variant === "warning") {
    return warnIcon;
  }
  if (variant === "info") {
    return infoIcon;
  }
};

const SnackMessage = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { message, variant } = props;

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <div
        style={{ backgroundColor: getAlertBg(variant) }}
        className={classes.card}
      >
        <img className={classes.icon} src={getAlertIcon(variant)} alt="icon" />
        <div
          style={{ color: getAlertText(variant) }}
          className={classes.typography}
        >
          {message}
        </div>
      </div>
    </SnackbarContent>
  );
});

export default SnackMessage;
