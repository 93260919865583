import {
  VIEW_PROJECT_DETAILS,
  VIEW_PROJECT_INFO,
} from "../actions/viewProjectAction";

const initialState = {
  project: {},
  projectInfo: {},
};

const viewProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_PROJECT_DETAILS: {
      return {
        ...state,
        project: action.payload,
      };
    }
    case VIEW_PROJECT_INFO: {
      return {
        ...state,
        projectInfo: action.payload,
      };
    }
    default:
      return state;
  }
};

export default viewProjectReducer;
