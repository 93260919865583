import { API } from "../constants/api";
import { axiosPostWithToken, axiosPut } from "../services/apiServices";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";

export const LIST_ACTIVITY_CARDS = "LIST_ACTIVITY_CARDS";
export const LOAD_ACTIVITY_LIST = "LOAD_ACTIVITY_LIST";
export const SEARCH_ACTIVITY_LIST = "SEARCH_ACTIVITY_LIST";
export const FILTER_ACTIVITY_LIST_FREETIME = "FILTER_ACTIVITY_LIST_FREETIME";
export const FILTER_ACTIVITY_LIST_SESSIONS = "FILTER_ACTIVITY_LIST_SESSIONS";
export const SET_ACTIVITY_ITEMS_PER_PAGE = "SET_ACTIVITY_ITEMS_PER_PAGE";
export const CHANGE_CURRENT_ACTIVITY_PAGE = "CHANGE_CURRENT_ACTIVITY_PAGE";

const getFreeTime = (value) => {
  if (value === "yes") {
    return true;
  } 
    return false;
};

export const getActivityCardList = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const activitySearchText = getState().availability.activitySearchText;
    const activityListFilterFreetime =
      getState().availability.activityListFilterFreetime;
    const activityListFilterSessions =
      getState().availability.activityListFilterSessions;
    const pageSize = getState().availability.activityListResultsPerPage;
    const page = getState().availability.activityListCurrentPage;

    const bodyData = {
      search_term: activitySearchText,
      filter_data: {
        free_time:
          activityListFilterFreetime.length === 1
            ? getFreeTime(activityListFilterFreetime[0])
            : null,
        sessions: activityListFilterSessions,
      },
    };

    axiosPostWithToken(
      `${API.LIST_ACTIVITY_CARDS}/${pageSize}/${page}/`,
      bodyData,
      token
    )
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: LIST_ACTIVITY_CARDS,
            payload: res.data.data,
          });
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const addActivityCard = (data, refreshList) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;

    axiosPostWithToken(`${API.ADD_ACTIVITY_CARD}`, data, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SUCCESS_ALERT,
            payload: "Activity added successfully.",
          });
          refreshList();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res.data.data.message || "Something went wrong.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};
export const editActivityCard = (activity_id, body, refreshList) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;

    axiosPut(`${API.EDIT_ACTIVITY_CARD}/${activity_id}/`, body, token)
      .then((res) => {
        if (res.data.status_code === 200) {
          dispatch({
            type: SUCCESS_ALERT,
            payload: "Activity edited successfully.",
          });
          refreshList();
        } else {
          dispatch({
            type: ERROR_ALERT,
            payload: res?.data?.data?.message || "Something went wrong. Unable to process request.",
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: ERROR_ALERT,
          payload:
            (err && err.response && err.response.data) ||
            "Something went wrong.",
        });
      });
  };
};

export const search = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_ACTIVITY_LIST,
    });
    dispatch({
      type: SEARCH_ACTIVITY_LIST,
      payload: value,
    });
  };
};

export const filterFreeTime = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_ACTIVITY_LIST,
    });
    dispatch({
      type: FILTER_ACTIVITY_LIST_FREETIME,
      payload: value,
    });
  };
};

export const filterSessions = (sessions) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_ACTIVITY_LIST,
    });
    dispatch({
      type: FILTER_ACTIVITY_LIST_SESSIONS,
      payload: sessions,
    });
  };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_ACTIVITY_LIST,
    });
    dispatch({
      type: SET_ACTIVITY_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const changeCurrentPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: LOAD_ACTIVITY_LIST,
    });
    dispatch({
      type: CHANGE_CURRENT_ACTIVITY_PAGE,
      payload: value,
    });
  };
};
