import {
  GET_USERS,
  SET_ITEMS_PER_PAGE,
  CHANGE_CURRENT_PAGE,
  CHANGE_ROLE,
  LOADING,
  GET_SKILLS,
  SEARCH_USERS,
  STORE_USER_DETAILS,
  STORE_LEVEL_FILTER,
  STORE_SKILLS_FILTER,
  STORE_FUNCTIONS_FILTER,
  GET_FUNCTIONSS,
  RESET_FILTER,
  SEARCH_USER_MANAGEMENT,
  ACTIVE_PROJECTS_FILTER,
} from "../actions/userAction";

const initialState = {
  role: "admin",
  userList: [],
  userSearchText: "",
  skills: [],
  functions: [],
  totalResults: null,
  currentPage: 1,
  resultsPerPage: 10,
  totalPages: null,
  pageIds: [],
  fetchingData: true,
  userDetails: {},
  filteredLevel: [],
  filteredSkills: [],
  filteredFunctions: [],
  skillFilterList: [],
  functionFilterList: [],
  activeProjectsFilter: [],
  activeAppliedProjectFilter: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS: {
      return {
        ...state,
        userList: action.payload.result.results,
        totalResults: action.payload.result.total_results,
        totalPages: action.payload.result.total_pages,
        pageIds: action.payload.result.page_ids,
        skillFilterList: action.payload.filter_data.skills,
        functionFilterList: action.payload.filter_data.functions,
        activeProjectsFilter: action.payload.filter_data.active_projects,
        fetchingData: false,
      };
    }
    case SEARCH_USERS: {
      return {
        ...state,
        userList: action.payload.results,
        totalResults: action.payload.total_results,
        fetchingData: false,
      };
    }
    case GET_SKILLS: {
      return {
        ...state,
        skills: action.payload,
      };
    }
    case GET_FUNCTIONSS: {
      return {
        ...state,
        functions: action.payload,
      };
    }
    case SET_ITEMS_PER_PAGE: {
      return {
        ...state,
        resultsPerPage: action.payload,
      };
    }
    case STORE_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case STORE_LEVEL_FILTER: {
      return {
        ...state,
        filteredLevel: action.payload,
      };
    }
    case STORE_SKILLS_FILTER: {
      return {
        ...state,
        filteredSkills: action.payload,
      };
    }
    case STORE_FUNCTIONS_FILTER: {
      return {
        ...state,
        filteredFunctions: action.payload,
      };
    }
    case RESET_FILTER: {
      return {
        ...state,
        filteredLevel: action.payload,
        filteredSkills: action.payload,
        filteredFunctions: action.payload,
      };
    }
    case CHANGE_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    case CHANGE_ROLE: {
      return {
        ...state,
        role: action.payload,
      };
    }
    case SEARCH_USER_MANAGEMENT: {
      return {
        ...state,
        userSearchText: action.payload,
      };
    }
    case LOADING: {
      return {
        ...state,
        fetchingData: true,
      };
    }

    case ACTIVE_PROJECTS_FILTER: {
      return {
        ...state,
        activeAppliedProjectFilter: action.payload,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
