import {
  LIST_ACTIVITY_CARDS,
  LOAD_ACTIVITY_LIST,
  SEARCH_ACTIVITY_LIST,
  FILTER_ACTIVITY_LIST_FREETIME,
  FILTER_ACTIVITY_LIST_SESSIONS,
  SET_ACTIVITY_ITEMS_PER_PAGE,
  CHANGE_CURRENT_ACTIVITY_PAGE,
} from "../actions/availabilityAction";

const initialState = {
  activityList: [],
  activitySearchText: "",
  fetchingActivityCardData: true,
  //Filters
  activityListFilterFreetime: [],
  activityListFilterSessions: [],
  //Pagination
  activityListTotalResults: null,
  activityListCurrentPage: 1,
  activityListResultsPerPage: 5,
  activityListTotalPages: null,
  activityListPageIds: [],
};

const availabilityReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ACTIVITY_CARDS: {
      return {
        ...state,
        activityList: action.payload.results,
        activityListTotalResults: action.payload.total_results,
        activityListTotalPages: action.payload.total_pages,
        activityListPageIds: action.payload.page_ids,
        fetchingActivityCardData: false,
      };
    }
    case LOAD_ACTIVITY_LIST: {
      return {
        ...state,
        fetchingActivityCardData: true,
      };
    }
    case SEARCH_ACTIVITY_LIST: {
      return {
        ...state,
        activitySearchText: action.payload,
      };
    }
    case FILTER_ACTIVITY_LIST_FREETIME: {
      return {
        ...state,
        activityListFilterFreetime: action.payload,
      };
    }
    case FILTER_ACTIVITY_LIST_SESSIONS: {
      return {
        ...state,
        activityListFilterSessions: action.payload,
      };
    }
    case CHANGE_CURRENT_ACTIVITY_PAGE: {
      return {
        ...state,
        activityListCurrentPage: action.payload,
      };
    }
    case SET_ACTIVITY_ITEMS_PER_PAGE: {
      return {
        ...state,
        activityListResultsPerPage: action.payload,
      };
    }

    default:
      return state;
  }
};

export default availabilityReducer;
