import { FbBox } from "@flexibees/core";
import React from "react";

export default function Loader() {
  return (
    <FbBox
      style={{
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      Loading...
    </FbBox>
  );
}
