import React from "react";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import { FbSidebarItem } from "@flexibees/core";
import { Link, useRouteMatch } from "react-router-dom";

const menuItems = [
  {
    name: "Dashboard",
    icon: HomeOutlinedIcon,
    path: "/dashboard",
  },
  {
    name: "User Management",
    icon: PermIdentityOutlinedIcon,
    path: "/user",
  },
  {
    name: "All Candidates",
    icon: PeopleAltOutlinedIcon,
    path: "/candidate",
  },
  {
    name: "Configuration",
    icon: SettingsOutlinedIcon,
    path: "/configuration",
  },
  {
    name: "Confirmation",
    icon: CheckCircleOutlineIcon,
    path: "/confirmation",
  },
  {
    name: "Availability",
    icon: AccessTimeOutlinedIcon,
    path: "/availability",
  },
];

export default function MenuItems() {
  const [activeMenuIndex, setactiveMenuIndex] = React.useState(
    menuItems.findIndex((mItem) => window.location.href.includes(mItem.path))
  );
  const [activeMenu, setActiveMenu] = React.useState(
    activeMenuIndex < 0 ? "" : menuItems[activeMenuIndex].name
  );

  React.useEffect(() => {
    setactiveMenuIndex(
      menuItems.findIndex((mItem) => window.location.href.includes(mItem.path))
    );
  }, [window.location.href]);

  React.useEffect(() => {
    setActiveMenu(activeMenuIndex < 0 ? "" : menuItems[activeMenuIndex].name);
  }, [activeMenuIndex]);

  const changeActiveMenu = (menu) => {
    setActiveMenu(menu);
  };

  const { path } = useRouteMatch();

  return (
    <div>
      {menuItems.map((item, index) => {
        return (
          <Link to={`${path}${item.path}`} style={{ textDecoration: "none" }}>
            <FbSidebarItem
              name={item.name}
              icon={item.icon}
              key={index}
              activeMenu={activeMenu}
              changeActiveMenu={changeActiveMenu}
            />
          </Link>
        );
      })}
    </div>
  );
}
