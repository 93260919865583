import { API } from "../constants/api";
import {
  axiosGetWithToken,
  axiosPostWithToken,
  axiosPatchWithToken,
} from "../services/apiServices";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";

export const GET_CONFIGURATION = "GET_CONFIGURATION";
export const GET_FUNCTIONS = "GET_FUNCTIONS";
export const SEARCH_CONFIGURATION = "SEARCH_CONFIGURATION";
export const GET_CONFIG_DROPDOWN = "GET_CONFIG_DROPDOWN";
export const CONFIG_LOADING = "CONFIG_LOADING";
export const GET_ROLLS_SKILL_MAPPING = "GET_ROLLS_SKILL_MAPPING";

export const get_configuration = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(API.GET_CONFIGURATION, token).then((res) => {
      if (res.data.status_code === 200) {
        let payload = res.data.data.fixed_configs.concat(
          res.data.data.added_configs
        );
        dispatch({
          type: CONFIG_LOADING,
        });
        dispatch({
          type: GET_CONFIGURATION,
          payload: payload,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message || "Unable to fetch Configuration data.",
        });
      }
    });
  };
};

export const get_functions = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(API.GET_FUNCTIONS, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_FUNCTIONS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to fetch Function tags!! Please try again.",
        });
      }
    });
  };
};

export const get_rolls_skill_mapping = (id) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.GET_ROLLS_SKILL_MAPPING}/${id}`, token).then((res) => {
      const updatedData = res?.data?.data.map((item, index) => {
        // Ensure 'skills' key is present
        const updatedItem = { ...item, id: index };
        if (!updatedItem.skills) {
          updatedItem.skills = [];
        }
        return updatedItem;
      });

      if (res.data.status_code === 200) {
        dispatch({
          type: GET_ROLLS_SKILL_MAPPING,
          payload: updatedData,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res?.data?.data?.message ||
            "Unable to fetch Rolls and skills!! Please try again.",
        });
      }
    }).catch((error) => {
      dispatch({
        type: ERROR_ALERT,
        payload: error.message || "An error occurred while fetching data.",
      });
    });
  };
};


export const search_rollskillMapping = (searchText, id) => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(`${API.GET_ROLLS_SKILL_MAPPING}/${id}?search_term=${searchText}`, token).then((res) => {
      const updatedData = res?.data?.data.map((item, index) => {
        // Ensure 'skills' key is present
        const updatedItem = { ...item, id: index };
        if (!updatedItem.skills) {
          updatedItem.skills = [];
        }
        return updatedItem;
      });

      if (res.data.status_code === 200) {
        dispatch({
          type: GET_ROLLS_SKILL_MAPPING,
          payload: updatedData,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to fetch search results. Please try again.",
        });
      }
    }).catch((error) => {
      dispatch({
        type: ERROR_ALERT,
        payload: error.message || "An error occurred while fetching data.",
      });
    });
  };
};


export const get_getConfigDropdown = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosGetWithToken(API.GET_CONFIG_DROPDOWN, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: GET_CONFIG_DROPDOWN,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to fetch Configuration Title List tags!! Please try again.",
        });
      }
    });
  };
};

export const search_configuration = (searchText) => {
  const body = {
    query: searchText,
  };
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPostWithToken(API.SEARCH_CONFIGURATION, body, token).then((res) => {
      // let data_payload = [];
      if (res.data.status_code === 200) {
        let payload = res.data.data.fixed_configs.concat(
          res.data.data.added_configs
        );
        // data_payload =
        //   res &&
        //   res.data &&
        //   res.data.data &&
        //   res.data.data.fixed_configs &&
        //   res.data.data.fixed_configs.concat(
        //     res.data.data.added_configs && res.data.data.added_configs
        //   );

        dispatch({
          type: CONFIG_LOADING,
        });

        dispatch({
          type: SEARCH_CONFIGURATION,
          payload: payload,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to fetch search results. Please try again.",
        });
      }
    });
  };
};

export const create_configuration = (
  title,
  tags,
  configListID,
  refreshTable
) => {
  const body = {
    title: title,
    tags: tags,
    dropdown: configListID === "select_field" ? null : configListID,
  };

  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPostWithToken(API.CREATE_CONFIGURATION, body, token).then((res) => {
      if (res.data.status_code === 201) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Configuration added successfully",
        });
        refreshTable();
      } else if (res.data.status_code === 400) {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
        // alert(`Error: ${res.data.data.message}`);
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to create Configuration. Please try again.",
        });
      }
    });
  };
};

export const add_functionTag = (tags, refreshTable) => {
  const body = {
    name: tags,
  };

  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPostWithToken(API.ADD_FUNCTIONTAG, body, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Function Tags added successfully",
        });
        refreshTable();
      } else if (res.data.status_code === 400) {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to add Function tags. Please try again.",
        });
      }
    });
  };
};

export const add_skillTag = (tags, refreshTable, functionID) => {
  const body = {
    name: tags,
    function: functionID,
  };

  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPostWithToken(API.ADD_SKILLTAG, body, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Skill Tags added successfully",
        });
        refreshTable();
      } else if (res.data.status_code === 400) {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to add Skill tags. Please try again.",
        });
      }
    });
  };
};

export const add_domainTag = (tags, refreshTable) => {
  const body = {
    name: tags,
  };

  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPostWithToken(API.ADD_DOMAINTAG, body, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Domain Tags added successfully",
        });
        refreshTable();
      } else if (res.data.status_code === 400) {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to add Domain tags. Please try again.",
        });
      }
    });
  };
};

export const add_roleTag = (tags, refreshTable) => {
  const body = {
    name: tags,
  };

  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    axiosPostWithToken(API.ADD_ROLETAG, body, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Role Tags added successfully",
        });
        refreshTable();
      } else if (res.data.status_code === 400) {
        dispatch({
          type: ERROR_ALERT,
          payload: res.data.data.message,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Unable to add Role tags. Please try again.",
        });
      }
    });
  };
};

export const add_otherTag = (id, tags, refreshTable) => {
  const body = {
    tags: tags,
  };

  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    let api = `${API.ADD_OTHERTAG}${id}/`;
    axiosPatchWithToken(api, body, token).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: SUCCESS_ALERT,
          payload: "Tags added successfully",
        });
        refreshTable();
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message || "Unable to add Tags. Please try again.",
        });
      }
    });
  };
};