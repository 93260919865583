import { axiosPostWithToken, axiosGetWithToken } from "../services/apiServices";
import { API } from "../constants/api";
import { ERROR_ALERT, SUCCESS_ALERT } from "./commonAction";

export const SEARCH_ROLES = "SEARCH_ROLES";
export const ROLES_LOADING = "ROLES_LOADING";
export const CHANGE_CURRENT_ROLES_PAGE = "CHANGE_CURRENT_ROLES_PAGE";
export const FROM_ROLES_LISTING = "FROM_ROLES_LISTING";
export const FILTER_STATUS_ROLES = "FILTER_STATUS_ROLES";
export const GET_CUSTOM_ROLES_LIST = "GET_CUSTOM_ROLES_LIST";
export const SET_ROLES_ITEMS_PER_PAGE = "SET_ROLES_ITEMS_PER_PAGE";


export const fromCustomRoles = () => {
  return async (dispatch, getstate) => {
    const token = getstate().auth.token;
    const pagesize = getstate().customRoles.fromRolesResultsPerPage;
    const page = getstate().customRoles.rolesCurrentPage;
    const seachText = getstate().customRoles.rolesSearchText;
    const filterStatus = getstate().customRoles.appliedStatusFilter;

    axiosGetWithToken(
      `${API.GET_CUSTOM_ROLES_LISTING}?search_terms=${seachText ? seachText : ''}&filter_status=${filterStatus ? filterStatus : ''}&page=${page ? page : ''}&page_size=${pagesize ? pagesize : ''}`,
      token
    ).then((res) => {
      if (res.data.status_code === 200) {
        dispatch({
          type: FROM_ROLES_LISTING,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: ERROR_ALERT,
          payload:
            res.data.data.message ||
            "Something went wrong. Unable to fetch data from Zoho.",
        });
      }
    });
  };
};


export const search = (value) => {
    return async (dispatch) => {
      dispatch({
        type: ROLES_LOADING,
      });
      dispatch({
        type: SEARCH_ROLES,
        payload: value,
      });
    };
};

export const changeCurrentPage = (value) => {
    return async (dispatch) => {
      dispatch({
        type: ROLES_LOADING,
      });
      dispatch({
        type: CHANGE_CURRENT_ROLES_PAGE,
        payload: value,
      });
    };
};

export const setItemsPerPage = (value) => {
  return async (dispatch) => {
    dispatch({
      type: SET_ROLES_ITEMS_PER_PAGE,
      payload: value,
    });
  };
};

export const filterRolesStatus = (status) => {
    return async (dispatch) => {
      dispatch({
        type: FILTER_STATUS_ROLES,
        payload: status,
      });
    };
  };

  export const accept_customrole = (bodyData, handleClose, action_type) => {
    return async (dispatch, getstate) => {
      const token = getstate().auth.token;
      axiosPostWithToken(`${API.ACCEPT_CUSTOM_ROLE}`, bodyData, token).then((res) => {
        if (res.data.status_code === 200) {
          handleClose();
          dispatch(fromCustomRoles());
          dispatch({
            type: SUCCESS_ALERT,
            payload: `Custom Role was ${action_type === 'accept' ? 'accepted' : 'rejected'} successfully!!`,
          });
        } else {
          const errorMessage = res.data?.data?.errors?.[0] || "Internal Server Error";
          dispatch({
            type: ERROR_ALERT,
            payload: errorMessage,
          });
        }
      }).catch((error) => {
        dispatch({
          type: ERROR_ALERT,
          payload: "Internal Server Error",
        });
      });
    };
  };
  

  export const edited_customrole = (bodyData, handleClose, action_type) => {
    return async (dispatch, getState) => {
      const token = getState().auth.token;
      try {
        const res = await axiosPostWithToken(`${API.SET_EDITED_ROLES}`, bodyData, token);
  
        if (res.data.status_code === 200) {
          handleClose();
          dispatch(fromCustomRoles());
          dispatch({
            type: SUCCESS_ALERT,
            payload: `Custom Role was ${action_type === 'accept' ? 'Updated' : 'rejected'} successfully!!`,
          });
        } else if (res.data.status_code === 400) {
          // Display custom error message for 400 status code
          dispatch({
            type: ERROR_ALERT,
            payload: res?.data?.data?.message || "Unable to Edit Name of Custom Roles!! Please try again.",
          });
        } else {
          // Handle other status codes or errors
          dispatch({
            type: ERROR_ALERT,
            payload: "Unable to Edit Name of Custom Roles!! Please try again.",
          });
        }
      } catch (error) {
        dispatch({
          type: ERROR_ALERT,
          payload: "Unable to Edit Name of Custom Roles!! Please try again.",
        });
      }
    };
  };
  
